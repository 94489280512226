import React from "react"
import { Box } from "@chakra-ui/core"
import styled from 'styled-components'
import customTheme from "../utils/theme"
import RenderHTML from "./renderHTML"

const Wrapper = styled.div`
  background-color: #E5E4E4;
  width: 100%;
`

const CallOut = ({ data }) => {

  return (
    <Wrapper data-scroll-section>
      <Box
      maxW={1280}
      w="90%"
      px={[3,4,5,6,7]}
      py={[4,5,6,6,7]}
      color={customTheme.colors.blue}
      textAlign="center"
      fontSize={[customTheme.fontSizes["lg"],customTheme.fontSizes["2xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"],customTheme.fontSizes["4xl"],customTheme.fontSizes["5xl"]]}
      style={{ margin: `0 auto` }}
      className="text-animate"
      data-scroll data-scroll-class="in-view"
      >
        <RenderHTML html={data} />
      </Box>
    </Wrapper>
  )
}

export default CallOut
