import React from "react"
import { navigate } from 'gatsby-link'
import { Flex, Box, Heading, FormControl, FormLabel, Input, Textarea, Button } from "@chakra-ui/core"
import styled from 'styled-components'
import customTheme from "../utils/theme"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Wrapper = styled.div`
  background-color: ${customTheme.colors.lightgray};
  width: 100%;
`

const StyledLabel = styled(FormLabel)`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`

const StyledInput = styled(Input)`
  background-color: ${customTheme.colors.lightgray} !important;
  border: 1px solid ${customTheme.colors.white} !important;
  border-radius: 0 !important;
  color: ${customTheme.colors.blue};
  font-weight: 700;
  padding: 1.5rem !important;
  text-transform: uppercase;

  &::placeholder {
    color: ${customTheme.colors.blue};
  }

  &:focus {
    border-color: ${customTheme.colors.blue} !important;
  }
`

const StyledTextarea = styled(Textarea)`
  background-color: ${customTheme.colors.lightgray} !important;
  border: 1px solid ${customTheme.colors.white} !important;
  border-radius: 0 !important;
  color: ${customTheme.colors.blue};
  font-weight: 700;
  padding: 1.5rem !important;
  text-transform: uppercase;
  height: 10rem !important;
  resize: none;

  &::placeholder {
    color: ${customTheme.colors.blue};
  }

  &:focus {
    border-color: ${customTheme.colors.blue} !important;
  }
`

export default class ContactForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = { isValidated: false }
    console.log(`props`,props)
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Wrapper data-scroll-section>
      <Box
      BackWrap="wrap"
      maxW={1430}
      w="90%"
      py={[4,5,5,5,6]}
      color={customTheme.colors.blue}
      fontSize={[customTheme.fontSizes["md"],customTheme.fontSizes["lg"],customTheme.fontSizes["lg"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"]]}
      borderTop={`1px solid ${customTheme.colors.white}`}
      style={{ margin: `0 auto` }}
      className="text-animate"
      data-scroll data-scroll-class="in-view"
      >
        <Heading as="h2" mb={[3,3,4,5]} fontWeight="500" fontSize={[customTheme.fontSizes["lg"],customTheme.fontSizes["2xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"],customTheme.fontSizes["4xl"],customTheme.fontSizes["5xl"]]} textAlign="center">{this.props.data.formHeadline}</Heading>
        <form
          name="contact"
          method="post"
          action="/contact-confirmation/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>

          <FormControl mb={[2,2,3]}>
            <StyledLabel for="email">Email</StyledLabel>
            <StyledInput type="email" id="email" name="email" placeholder="Email" onChange={this.handleChange} isRequired />
          </FormControl>
          <FormControl mb={[2,2,3]}>
            <StyledLabel for="subject">Subject</StyledLabel>
            <StyledInput type="subject" id="subject" name="subject" placeholder="Subject" onChange={this.handleChange} isRequired />
          </FormControl>
          <FormControl>
            <StyledLabel for="message">Message</StyledLabel>
            <StyledTextarea type="message" id="message" name="message" placeholder="Message" onChange={this.handleChange} isRequired></StyledTextarea>
          </FormControl>
          <Flex justify="center" mt={[3,3,4,5]}>
            <Button bg={customTheme.colors.blue} borderRadius="50px" h="4rem" color={customTheme.colors.white} textTransform="uppercase" fontSize={[customTheme.fontSizes["sm"],customTheme.fontSizes["sm"],customTheme.fontSizes["md"],customTheme.fontSizes["sm"],customTheme.fontSizes["md"],customTheme.fontSizes["lg"]]} letterSpacing="0.05rem" py={2} px={6} _hover={{ bg: customTheme.colors.gold, color: customTheme.colors.white }} type="submit">Submit</Button>
          </Flex>
          <input type="hidden" name="form-name" value="contact" />
        </form>
      </Box>
    </Wrapper>
    )
  }
}
/*
const ContactForm = ({ data }) => {
  return (
    <Wrapper data-scroll-section>
      <Box
      BackWrap="wrap"
      maxW={1430}
      w="90%"
      py={[4,5,5,5,6]}
      color={customTheme.colors.blue}
      fontSize={[customTheme.fontSizes["md"],customTheme.fontSizes["lg"],customTheme.fontSizes["lg"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"]]}
      borderTop={`1px solid ${customTheme.colors.white}`}
      style={{ margin: `0 auto` }}
      className="text-animate"
      data-scroll data-scroll-class="in-view"
      >
        <Heading as="h2" mb={[3,3,4,5]} fontWeight="500" fontSize={[customTheme.fontSizes["lg"],customTheme.fontSizes["2xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"],customTheme.fontSizes["4xl"],customTheme.fontSizes["5xl"]]} textAlign="center">{data.formHeadline}</Heading>
        <form name="contact" method="POST" data-netlify="true" action="/contact-confirmation/">
          <FormControl mb={[2,2,3]}>
            <StyledLabel for="email">Email</StyledLabel>
            <StyledInput type="email" id="email" name="email" placeholder="Email" />
          </FormControl>
          <FormControl mb={[2,2,3]}>
            <StyledLabel for="subject">Subject</StyledLabel>
            <StyledInput type="subject" id="subject" name="subject" placeholder="Subject" />
          </FormControl>
          <FormControl>
            <StyledLabel for="message">Message</StyledLabel>
            <StyledTextarea type="message" id="message" name="message" placeholder="Message"></StyledTextarea>
          </FormControl>
          <Flex justify="center" mt={[3,3,4,5]}>
            <Button bg={customTheme.colors.blue} borderRadius="50px" h="4rem" color={customTheme.colors.white} textTransform="uppercase" fontSize={[customTheme.fontSizes["sm"],customTheme.fontSizes["sm"],customTheme.fontSizes["md"],customTheme.fontSizes["sm"],customTheme.fontSizes["md"],customTheme.fontSizes["lg"]]} letterSpacing="0.05rem" py={2} px={6} _hover={{ bg: customTheme.colors.gold, color: customTheme.colors.white }} type="submit">Submit</Button>
          </Flex>
          <input type="hidden" name="form-name" value="contact" />
        </form>
      </Box>
    </Wrapper>
  )
}

export default ContactForm*/