import React from "react"
import { Heading, Flex, Box, Text, Image } from "@chakra-ui/core"
import customTheme from "../../utils/theme"
import styled, { css } from "styled-components"
import PropTypes from 'prop-types'

const StyledFlex = styled(Flex)`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1px;
    height: 80%;
    z-index: 0;

    ${props => props.lineColor && css`
      background: ${props.lineColor};
    `}
  }
`

Text.propTypes = {
  lineColor: PropTypes.string,
};

const ChartBlock = ({ data, bg, text, styles }) => {
  return (
  <StyledFlex px={[3, 3, 4, 3, 4, 5]} py={[3, 3, 3, 3, 4, 5]} height="100%" bg={customTheme.colors[bg]} color={customTheme.colors[text]} textAlign="center" flexDirection="column" justifyContent="space-between" lineColor={customTheme.colors[text]}>
    <Box bg={customTheme.colors[bg]} pb={2} zIndex="1">
      <Heading as="h2" fontWeight="400" fontSize={styles.heading}>{data.headline}</Heading>
    </Box>
    <Box bg={customTheme.colors[bg]} px={[0,2,3,2]} py={2} zIndex="1">
      <Image src={data.chart.localFile.publicURL} mx="auto" />
    </Box>
    { data.description &&
      <Box bg={customTheme.colors[bg]} py={2} zIndex="1">
        <Text fontSize={styles.text}>{data.description}</Text>
      </Box>
    }
  </StyledFlex>
  )
}

export default ChartBlock
