import React from "react"
import { Box, Flex, Heading, Text } from "@chakra-ui/core"
import styled from 'styled-components'
import customTheme from "../utils/theme"
import Img from "gatsby-image"

const Wrapper = styled.div`
  background-color: ${customTheme.colors.lightgray};
  width: 100%;
`

const NameBox = styled(Box)`
  position: relative;

  &:after {
    background: ${customTheme.colors.blue};
    content: '';
    position: absolute;
    bottom: 0;
    left: 2rem;
    width: 1.5rem;
    height: 2px;

    @media (min-width: ${customTheme.breakpoints[2]}) {
      left: 3rem;
    }
    @media (min-width: ${customTheme.breakpoints[3]}) {
      left: 4rem;
    }
  }
`

const TextBox = styled(Box)`
  position: relative;

  &:after {

    @media (min-width: ${customTheme.breakpoints[1]}) {
      background: ${customTheme.colors.blue};
      content: '';
      position: absolute;
      bottom: 0;
      left: 2rem;
      width: 1.5rem;
      height: 2px;
    }
    @media (min-width: ${customTheme.breakpoints[2]}) {
      left: 3rem;
    }
    @media (min-width: ${customTheme.breakpoints[3]}) {
      left: 4rem;
    }
  }
`


const Team = ({ data }) => {

  return (
    <Wrapper data-scroll-section>
      {data.map((value, index) => {
        return (
          <Flex
          flexWrap="wrap"
          maxW={1720}
          w="90%"
          py={[4,5,6,6,7]}
          color={customTheme.colors.blue}
          fontSize={[customTheme.fontSizes["sm"],customTheme.fontSizes["md"],customTheme.fontSizes["md"],customTheme.fontSizes["sm"],customTheme.fontSizes["lg"],customTheme.fontSizes["2xl"]]}
          borderTop={[`12px solid ${customTheme.colors.blue}`,`12px solid ${customTheme.colors.blue}`,`25px solid ${customTheme.colors.blue}`]}
          style={{ margin: `0 auto` }}
          key={index}
          >
            <Box w={["50%","50%","25%"]} zIndex="1" data-scroll data-scroll-speed="4">
              <Img fluid={value.image.localFile.childImageSharp.fluid} />
            </Box>
            <NameBox
              w={["50%","50%","25%"]}
              pl={[3,3,3,4,5]}
              className="text-animate"
              data-scroll data-scroll-class="in-view"
            >
              <Heading as="h3" color={customTheme.colors.blue} mb={3} fontSize={[customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"]]}>{value.name}</Heading>
              <Text>{value.title}</Text>
            </NameBox>
            {value.description &&
              <TextBox
                w={["100%","100%","50%"]}
                pl={[0,0,3,4,5]}
                mt={[3,3,"0px"]}
                pb={[0,0,4]}
                className="text-animate"
                data-scroll data-scroll-class="in-view"
              >
                <Text>{value.description}</Text>
              </TextBox>
            }
          </Flex>
        )
      })}
    </Wrapper>
  )
}

export default Team