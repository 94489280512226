import React from "react"
import { Heading, Box, Text } from "@chakra-ui/core"
import customTheme from "../../utils/theme"
import styled, { css } from "styled-components"
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import Swiper from 'react-id-swiper'
import "swiper/swiper-bundle.esm.js"

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

const StyledBox = styled(Box)`
  &:after {
    content: '';
    position: absolute;
    bottom: 2.5rem;
    left: 2rem;
    width: 1.5rem;
    height: 1px;

    ${props => props.lineColor && css`
      background: ${props.lineColor};
    `}

    @media (min-width: ${customTheme.breakpoints[2]}) {
      left: 2rem;
      height: 2px;
    }
    @media (min-width: ${customTheme.breakpoints[3]}) {
      left: 3rem;
      bottom: 4rem;
    }
  }
`

Text.propTypes = {
  lineColor: PropTypes.string,
};

const FaderBlock = ({ data, pt, styles, text }) => {

  const sliderParams = {
    containerClass: "fader-block",
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    speed: 500,
    effect: "fade"
  }

  return (
    <Box paddingTop={pt} position="relative">
      <StyledBox px={[3, 3, 3, 3, 4]}  py={[3, 3, 3, 3, 4, 5]} position="absolute" top="0px" left="0px" width="100%" height="100%" zIndex="1" lineColor={text ? customTheme.colors[text] : "transparent"}>
        <Heading as="h2" mb={2} fontWeight="400" fontSize={styles.heading}>{data.headline}</Heading>
        { data.attribution &&
          <Text mt={[2,2,3]} fontSize={styles.button}>{data.attribution}</Text>
        }
      </StyledBox>
      <Box position="absolute" top="0px" left="0px" width="100%" height="100%" zIndex="0">
        <Swiper {...sliderParams}>
        { data.backgrounds.map((value, index) => {
          return (
            <BackgroundImage
            Tag="div"
            fluid={value.localFile.childImageSharp.fluid}
            key={index}
            ></BackgroundImage>
          )
        })}
        </Swiper>
      </Box>
    </Box>
  )
}

export default FaderBlock
