import React from "react"
import { Grid, Box } from "@chakra-ui/core"
import { ContentBlock, ContentImageBlock, ImageBlock, HeadlineBlock, FaderBlock, ChartBlock } from '../components/grid-blocks'
import styled from 'styled-components'
import customTheme from "../utils/theme"

const Wrapper = styled.div`
  background-color: ${customTheme.colors.lightgray};
  position: relative;
  width: 100%;
  z-index: 1;
`

const TabBox = styled(Box)`
  position: relative;

  &:before {
    @media (min-width: ${customTheme.breakpoints[2]}) {
      background: ${customTheme.colors.white};
      content: '';
      position: absolute;
      top: -25px;
      left: 0;
      width: 100%;
      height: 25px;
    }
    @media (min-width: ${customTheme.breakpoints[3]}) {
      top: -50px;
      height: 50px;
    }
  }
`


const ContentGrid = ({ data }) => {

  const styles = {
    "heading" : [ customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"] ],
    "text" : [ customTheme.fontSizes["sm"],customTheme.fontSizes["md"],customTheme.fontSizes["md"],customTheme.fontSizes["sm"],customTheme.fontSizes["lg"],customTheme.fontSizes["2xl"] ],
    "button" : [ customTheme.fontSizes["sm"],customTheme.fontSizes["sm"],customTheme.fontSizes["md"],customTheme.fontSizes["sm"],customTheme.fontSizes["md"],customTheme.fontSizes["lg"] ]
  }

  return (
    <Wrapper data-scroll-section>
      <Grid templateColumns={["repeat(2, minmax(0, 1fr))", "repeat(2, minmax(0, 1fr))", "repeat(2, minmax(0, 1fr))", "repeat(4, minmax(0, 1fr))"]} maxW={1720} w={["90%","75%","68%","90%"]} style={{ margin: `0 auto` }} color={customTheme.colors.blue}>
        <TabBox className="text-animate" gridColumn="1 / 3" bg={customTheme.colors.white} color={customTheme.colors.blue} mt={[3,4,4,"0px"]} data-scroll data-scroll-class="in-view">
          <ContentBlock data={data.contentBlocks[0]} pt={["75%", "60%", "55%", "55%", "50%"]} styles={styles} text="blue"></ContentBlock>
        </TabBox>
        <Box className="text-animate" gridColumn={["1 / 3", "1 / 3", "1 / 3", "3 / 5"]} bg={customTheme.colors.gray} color={customTheme.colors.white} data-scroll data-scroll-class="in-view">
          <HeadlineBlock data={data.headlineBlocks[0]} pt={["75%", "60%", "55%", "55%", "50%"]} styles={styles} text="white"></HeadlineBlock>
        </Box>
        <Box className="text-animate" gridColumn="1 / 3" gridRow={["auto", "auto", "auto", "2 / 4"]} data-scroll data-scroll-class="in-view">
          <ContentImageBlock data={data.contentImageBlocks[0]} pt={["0px","110%","105%","105%","100%"]} styles={styles}></ContentImageBlock>
        </Box>
        <Box id="image-block-1">
          <Box className="parallax" zIndex="2" data-scroll data-scroll-speed="15">
            <ImageBlock data={data.imageBlocks[0]} pt="100%"></ImageBlock>
          </Box>
        </Box>
        <Box className="parallax" zIndex="2" data-scroll data-scroll-speed="8">
          <ImageBlock data={data.imageBlocks[1]} pt="100%"></ImageBlock>
        </Box>
        <Box className="parallax" zIndex="2" data-scroll data-scroll-speed="5">
          <ImageBlock data={data.imageBlocks[2]} pt="100%"></ImageBlock>
        </Box>
        <Box className="parallax" zIndex="2" data-scroll data-scroll-speed="20">
          <ImageBlock data={data.imageBlocks[3]} pt="100%"></ImageBlock>
        </Box>
        <Box className="text-animate" bg={customTheme.colors.blue} color={customTheme.colors.white} zIndex="2" data-scroll data-scroll-class="in-view" data-scroll-speed="5">
          <ContentBlock data={data.contentBlocks[1]} pt="100%" styles={styles} text="blue"></ContentBlock>
        </Box>
        <Box className="text-animate" gridColumn="2 / 3" gridRow={["6 / 8","6 / 8","6 / 8","4 / 6"]} bg={customTheme.colors.gold} color={customTheme.colors.white} data-scroll data-scroll-class="in-view">
          <ChartBlock data={data.chartBlocks[0]} pt="200%" styles={styles} bg="gold" text="white"></ChartBlock>
        </Box>
        <Box className="text-animate" gridColumn="1 / 2" gridRow={["7 / 9","7 / 9","7 / 9","5 / 7"]} bg={customTheme.colors.white} color={customTheme.colors.gold} data-scroll data-scroll-class="in-view">
          <HeadlineBlock data={data.headlineBlocks[1]} pt="200%" styles={styles} text=""></HeadlineBlock>
        </Box>
        <Box gridColumn="2 / 3" gridRow={["8 / 9","8 / 9","8 / 9","6 / 7"]} zIndex="2" data-scroll data-scroll-speed="8">
          <ImageBlock data={data.imageBlocks[4]} pt="100%"></ImageBlock>
        </Box>
        <Box className="text-animate" gridColumn={["1 / 3", "1 / 3", "1 / 3", "3 / 5"]} gridRow={["9 / 10","9 / 10","9 / 10","4 / 6"]} color={customTheme.colors.white} data-scroll data-scroll-class="in-view">
          <FaderBlock data={data.faderBlocks[0]} pt="100%" styles={styles} text="white"></FaderBlock>
        </Box>
        <Box className="text-animate" gridColumn={["1 / 3","1 / 3","1 / 3","3 / 5"]} gridRow={["10 / 11","10 / 11","10 / 11","6 / 7"]} bg={customTheme.colors.gray} color={customTheme.colors.white} data-scroll data-scroll-class="in-view">
          <HeadlineBlock data={data.headlineBlocks[2]} pt={["75%", "60%", "55%", "55%", "50%"]} styles={styles} text="white"></HeadlineBlock>
        </Box>
        <Box className="text-animate" gridColumn="1 / 3" data-scroll data-scroll-class="in-view">
          <ContentImageBlock data={data.contentImageBlocks[1]} pt={["0px","110%","105%","105%","100%"]}  styles={styles}></ContentImageBlock>
        </Box>
      </Grid>
    </Wrapper>
  )
}

export default ContentGrid
