import React from "react"
import Swiper from 'react-id-swiper'
import { Flex, Box } from "@chakra-ui/core"
import "swiper/swiper-bundle.esm.js"
import Img from "gatsby-image"
import styled from 'styled-components'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin)

const StyledFlex = styled(Flex)`
  padding-top: 56.22%;
  position: relative;
  width: 100%;
`


function setTransition(swiper, transitionSpeed) {
  gsap.to(".tile", 0, {width:"6.671%", opacity: 1})
}

function setTranslate(swiper, wrapperTranslate) {
  gsap.from('.tile', 0, {width: "6.671%", opacity: 1} )
  gsap.to('.tile-15', 0.85, {delay: 3.22, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-14', 0.85, {delay: 3.24, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-13', 0.85, {delay: 3.26, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-12', 0.85, {delay: 3.28, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-11', 0.85, {delay: 3.3, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-10', 0.85, {delay: 3.32, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-9', 0.85, {delay: 3.34, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-8', 0.85, {delay: 3.36, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-7', 0.85, {delay: 3.38, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-6', 0.85, {delay: 3.4, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-5', 0.85, {delay: 3.42, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-4', 0.85, {delay: 3.44, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-3', 0.85, {delay: 3.46, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-2', 0.85, {delay: 3.48, width: "0%", ease: "power3.out"} )
  gsap.to('.tile-1', 0.85, {delay: 3.5, width: "0%", ease: "power3.out"} )
  gsap.to('.tile', 0, {delay: 3.9, opacity: 0} )
}

const Carousel = ({ data }) => {


  const sliderParams = {
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: {
      delay: 4500,
      disableOnInteraction: false
    },
    speed: 0,
    effect: "fade",
    on: {
      setTransition(transition) {
        const swiper = this;
        setTransition(swiper, transition)
      },
      setTranslate(translate) {
        const swiper = this
        setTranslate(swiper, translate)
      }
    }
  }

  return (
    <div data-scroll-section>
      <Swiper {...sliderParams}>
        {data.map((value, index) => {
          const nextImage = data[index + 1] ? data[index + 1].image : data[0].image;
          return (
            <StyledFlex key={index}>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="0px"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-1"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="6.67%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-2"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-6.67%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="13.34%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-3"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-13.34%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="20.01%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-4"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-20.01%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="26.68%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-5"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-26.68%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="33.35%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-6"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-33.35%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="40.02%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-7"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-40.02%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="46.69%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-8"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-46.69%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="53.36%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-9"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-53.36%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="60.03%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-10"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-60.03%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="66.7%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-11"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-66.7%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="73.37%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-12"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-73.37%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="80.04%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-13"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-80.04%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="86.71%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-14"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-86.71%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="8%"
                h="100%"
                position="absolute"
                top="0px"
                left="93.38%"
                style={{overflow: "hidden", zIndex: 1}}
                className="tile tile-15"
              >
                <Img
                  fluid={value.image.localFile.childImageSharp.fluid}
                  style={{width: "100vw"}}
                  imgStyle={{left: "-93.38%"}}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
              <Box
                w="100%"
                h="100%"
                position="absolute"
                top="0px"
                left="0px"
                style={{overflow: "hidden", zIndex: 0}}
              >
                <Img
                  fluid={nextImage.localFile.childImageSharp.fluid}
                  loading={ index === 0 ? "eager" : "lazy" }
                  fadeIn={ index === 0 ? false : true }
                />
              </Box>
            </StyledFlex>
          )
        })}
      </Swiper>
    </div>
  )
}

export default Carousel



// GSAP venetian blinds effect https://greensock.com/forums/topic/14697-venetian-mask-effects-on-gsap/
// Swiper JS custom GSAP transitions https://medium.com/@arnost/creating-custom-slide-transitions-in-swiper-js-also-with-gsap-ac71f9badf53

// Lazy load swiper bg images https://swiperjs.com/api/#lazy ... convert from using gatsby bg image