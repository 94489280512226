import React from "react"
import { Heading, Text, Flex, Box } from "@chakra-ui/core"
import customTheme from "../../utils/theme"
import styled, { css } from "styled-components"
import PropTypes from 'prop-types'


const StyledFlex = styled(Flex)`
  &:after {
    content: '';
    position: absolute;
    bottom: 2.5rem;
    left: 2rem;
    width: 1.5rem;
    height: 1px;

    ${props => props.lineColor && css`
      background: ${props.lineColor};
    `}

    @media (min-width: ${customTheme.breakpoints[2]}) {
      left: 2rem;
      height: 2px;
    }
    @media (min-width: ${customTheme.breakpoints[3]}) {
      left: 3rem;
      bottom: 4rem;
    }
  }
`

Text.propTypes = {
  lineColor: PropTypes.string,
};

const ContentBlock = ({ data, pt, text, styles }) => {
  return (
    <Box pt={pt} pb position="relative" textAlign={data.textAlignment}>
      <StyledFlex flexDirection="column" justifyContent={data.textAlignment === "center" ? "center" : "flex-start"} alignItems={data.textAlignment === "center" ? "center" : "flex-start"} pl={[3, 3, 3, 3, 4]} pr={[3, 3, 4, 4, 5, 6]} py={[3, 3, 3, 3, 4, 5]} position="absolute" top="0px" left="0px" width="100%" height="100%" lineColor={customTheme.colors[text]}>
        {data.headline &&
          <Heading as="h2" mb={2} fontWeight="500" fontSize={styles.heading}>{data.headline}</Heading>
        }
        {data.description &&
          <Text fontSize={styles.text} fontWeight={data.headline ? "400" : "500"}>{data.description}</Text>
        }
        {/* data.link &&
          <Flex justify="flex-end">
            <Button as="a" href={data.link.url} variant="outline" borderColor={customTheme.colors.green} borderRadius="50px" textTransform="uppercase" fontSize={styles.button} letterSpacing="0.05rem" py={3} _hover={{ bg: customTheme.colors.blue, color: customTheme.colors.white }}>{ data.link.title }</Button>
          </Flex>
        */}
      </StyledFlex>
    </Box>
  )
}

export default ContentBlock
