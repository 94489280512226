import React from "react"
import { Heading, Text, Box, Flex } from "@chakra-ui/core"
import Img from "gatsby-image"
import customTheme from "../../utils/theme"
import styled from "styled-components"


const StyledBox = styled(Box)`
  &:after {
    background: ${customTheme.colors.blue};
    content: '';
    position: absolute;
    bottom: 2.5rem;
    left: 2rem;
    width: 1.5rem;
    height: 1px;

    @media (min-width: ${customTheme.breakpoints[2]}) {
      left: 2rem;
      height: 2px;
    }
    @media (min-width: ${customTheme.breakpoints[3]}) {
      left: 3rem;
      bottom: 4rem;
    }
  }
`

const ContentImageBlock = ({ data, pt, styles }) => {
  return (
    <Box pt={pt} position="relative">
      <Flex flexDirection="column" position={["relative","absolute"]} top="0px" left="0px" width="100%" height="100%" border={`8px solid ${customTheme.colors.white}`}>
        <Box>
          <Img fluid={data.image.localFile.childImageSharp.fluid} alt={data.image.altText} />
        </Box>
        <Flex align="flex-start" flexGrow="1">
          <StyledBox pl={[3, 3, 3, 3, 4]} pr={[3, 3, 4, 4, 5, 6]} py={3}>
            <Heading as="h2" mb={2} fontWeight="500" fontSize={styles.heading}>{data.headline}</Heading>
            <Text pb={3} mb={3} fontSize={styles.text}>{data.description}</Text>
            {/* data.link &&
              <Flex justify="flex-end">
                <Button as="a" href={data.link.url} variant="outline" borderColor={customTheme.colors.green} borderRadius="50px" textTransform="uppercase" fontSize={styles.button} letterSpacing="0.05rem" py={3} _hover={{ bg: customTheme.colors.blue, color: customTheme.colors.white }}>{ data.link.title }</Button>
              </Flex>
            */}
          </StyledBox>
        </Flex>
      </Flex>
    </Box>
  )
}

export default ContentImageBlock
