import React from "react"
import { Flex, Box, Heading, Text, Grid } from "@chakra-ui/core"
import styled from 'styled-components'
import customTheme from "../utils/theme"
import Img from "gatsby-image"

const Wrapper = styled.div`
  background-color: ${customTheme.colors.gray};
  width: 100%;
`


const Capabilities = ({ data }) => {

  return (
    <Wrapper data-scroll-section>
      <Flex
      flexWrap="wrap"
      maxW={1430}
      w="90%"
      py={[4,5,5,5,6]}
      color={customTheme.colors.white}
      fontSize={[ customTheme.fontSizes["sm"],customTheme.fontSizes["md"],customTheme.fontSizes["lg"],customTheme.fontSizes["sm"],customTheme.fontSizes["lg"],customTheme.fontSizes["2xl"] ]}
      style={{ margin: `0 auto` }}
      >
        <Box
          w={["100%", "100%", "50%"]}
          pr={[0,0,3]}
          mb={3}
          className="text-animate"
          data-scroll data-scroll-class="in-view"
        >
          <Heading as="h2" fontWeight="400" fontSize={[customTheme.fontSizes["xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"],customTheme.fontSizes["4xl"]]}>{data.capabilitiesHeadline}</Heading>
        </Box>
        <Box
          w={["100%", "100%", "50%"]}
          className="text-animate"
          data-scroll data-scroll-class="in-view"
        >
          {data.capabilities.map((value, index) => {
            return (
              <Grid templateColumns={["40px auto","40px auto","56px auto","56px auto","64px auto","80px auto"]} pt={index > 0 ? 3 : '0px'} key={index}>
                <Box
                  w={["40px","40px","56px","56px","64px","80px"]}
                  h={["40px","40px","56px","56px","64px","80px"]}
                  pt={["10px","10px", "14px"]}
                  border="1px solid white"
                  borderRadius="100%"
                  textAlign="center"
                  color={customTheme.colors.blue}
                  fontSize={[ customTheme.fontSizes["md"],customTheme.fontSizes["lg"],customTheme.fontSizes["xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"] ]}
                  fontWeight="500"
                  className="number"
                >{index + 1}</Box>
                <Box pl={2}><Text fontWeight="500">{value.capability}</Text></Box>
              </Grid>
            )
          })}
        </Box>
        {data.logos &&
          <Box w={"100%"} mt={[5,5,6,7]}>
            <Grid templateColumns={[`repeat(2, minmax(0, 1fr))`, `repeat(3, minmax(0, 1fr))`, `repeat(3, minmax(0, 1fr))`, `repeat(${data.logos.length}, minmax(0, 1fr))`]}>
              {data.logos.map((value, index) => {
                return (
                  <Box key={index} px={[3,3,4]} py={[2]} my={[1]} ml="-1px" borderRight="1px solid white" borderLeft="1px solid white">
                    <Img
                      fluid={value.localFile.childImageSharp.fluid}
                      loading={"lazy"}
                    />
                  </Box>
                )
              })}
            </Grid>
          </Box>
        }
      </Flex>
    </Wrapper>
  )
}

export default Capabilities