import React from "react"
import { Box } from "@chakra-ui/core"
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import customTheme from "../../utils/theme"
import cubeGIF from '../../assets/gifs/cube.gif'
import sphereGIF from '../../assets/gifs/sphere.gif'


const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  padding-top: 100%;
`

const StyledBox = styled(Box)`
  width: 100%;
  height: 100%;
`

const ImageBlock = ({ data }) => {
  return (
    <Box height="100%">
      { data.image.mimeType !== "image/gif" &&
        <StyledBackgroundImage
          Tag="div"
          fluid={data.image.localFile.childImageSharp.fluid}
          backgroundColor={customTheme.colors.lightgray}
        />
      }
      { data.image.mimeType === "image/gif" &&
        <StyledBox style={{backgroundImage: `url(${data.image.localFile.name === 'sphere' ? sphereGIF : cubeGIF})`, backgroundSize: "cover", backgroundPosition: "center"}}></StyledBox>
      }
    </Box>
  )
}

export default ImageBlock
