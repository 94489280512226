import React from 'react';
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Carousel from '../../components/carousel'
import ContentGrid from '../../components/contentGrid'
import CallOut from '../../components/callOut'
import Team from '../../components/team'
import ContactForm from '../../components/contactForm'
import Capabilities from '../../components/capabilities'

const IndexPage = ({ data: {
  wpPage: { acfHome }
} }) => {

  //acfPageHome.map((value) => (console.log(value)))
  // for (const [key, value] of Object.entries(acfPageHome)) {
  //   console.log(key)
  //   console.log(value)
  // }
  //console.log(acfPageHome.carousel)
  /*
  const components = {
    carousel: block.Carousel,
    contentBlocks: block.ContentBlock,
    contentImageBlocks: block.ContentImageBlock,
    imageBlocks: block.ImageBlock,
    statBlocks: block.StatBlock,
    headlineBlocks: block.HeadlineBlock
  }*/




  return (
    <Layout>
      <Carousel data={acfHome.carousel}></Carousel>
      <ContentGrid data={acfHome}></ContentGrid>
      {acfHome.callOut &&
        <CallOut data={acfHome.callOut}></CallOut>
      }
      {acfHome.capabilities &&
        <Capabilities data={acfHome}></Capabilities>
      }
      {acfHome.team &&
        <Team data={acfHome.team}></Team>
      }
      {acfHome.formHeadline &&
        <ContactForm data={acfHome}></ContactForm>
      }
    </Layout>

    /*
    <div>
      {Object.entries(acfPageHome).map((value, index) => {
        const BlockComponent = components[value[0]];
        return <BlockComponent key={index}></BlockComponent>
      })}
    </div>
    */
  )
  /*
  return (
    <Layout>
      <Box>
        <Heading as="h1" size="xl">{title}</Heading>
      </Box>
    </Layout>
  )*/
}

/*
const IndexPage = ({
  data: {
    wpPage: { title, acfPageHome },
  },
}) => (
    <Layout>
      <Box>
        <Heading as="h1" size="xl">{title}</Heading>
      </Box>

      {acfPageHome.map((block) => (
        <Box block={block}>test</Box>
      ))}
    </Layout>
  )*/

export default IndexPage;

export const pageQuery = graphql`
  query HomePage {
    wpPage(isFrontPage: { eq: true }) {
      title
      uri
      acfHome {
        carousel {
          image {
            localFile {
              name
              childImageSharp {
                fluid(quality: 70, maxWidth: 2880) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            altText
          }
        }
        contentBlocks {
          headline
          description
          link {
            title
            url
            target
          }
          textAlignment
        }
        contentImageBlocks {
          image {
            localFile {
              name
              childImageSharp {
                fluid(quality: 70, maxWidth: 1720) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            altText
          }
          headline
          description
          link {
            title
            url
            target
          }
        }
        imageBlocks {
          image {
            localFile {
              name
              childImageSharp {
                fluid(quality: 70, maxWidth: 860) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            mimeType
            altText
          }
        }
        headlineBlocks {
          headline
          attribution
        }
        faderBlocks {
          headline
          backgrounds {
            localFile {
              name
              childImageSharp {
                fluid(quality: 70, maxWidth: 860) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        chartBlocks {
          headline
          chart {
            localFile {
              name
              publicURL
            }
          }
          description
        }
        callOut
        capabilitiesHeadline
        capabilities {
          capability
        }
        logos {
          localFile {
            name
            childImageSharp {
              fluid(quality: 70, maxWidth: 860) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        team {
          name
          title
          image {
            localFile {
              name
              childImageSharp {
                fluid(quality: 70, maxWidth: 860) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            altText
          }
          description
        }
        formHeadline
      }
    }
  }
`;